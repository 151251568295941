const order = {
  namespaced: true,
  state: {
    orderParams: localStorage.getItem("orderParams")
      ? JSON.parse(localStorage.getItem("orderParams"))
      : {},
    defaultAddress: localStorage.getItem("defaultAddress")
      ? JSON.parse(localStorage.getItem("defaultAddress"))
      : {},
  },
  actions: {},
  mutations: {
    SET_ORDER_PARAMS: (state, params) => {
      state.orderParams = params;
      localStorage.setItem("orderParams", JSON.stringify(params));
    },
    REMOVE_ORDER_PARAMS: (state) => {
      state.orderParams = {};
      localStorage.removeItem("orderParams");
    },
    SET_DEFAULT_ADDRESS: (state, params) => {
      state.defaultAddress = params;
      localStorage.setItem("defaultAddress", JSON.stringify(params));
    },
    REMOVE_DEFAULT_ADDRESS: (state) => {
      state.defaultAddress = {};
      localStorage.removeItem("defaultAddress");
    },
  },
};
export default order;
