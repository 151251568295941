<!--
 * @Descripttion: 
 * @version: 
 * @Author: chenyu
 * @Date: 2024-09-24 19:04:49
 * @LastEditors: chenyu
 * @LastEditTime: 2024-09-27 19:22:52
-->
<template>
  <transition name="el-fade-in-linear" mode="out-in">
    <div class="dialogCss" v-show="dialogVisible" @click="close">
      <div class="addCartBox">
        <div class="addCartBoxSon" @click.stop>
          <div class="addIcon" style="margin-bottom: 10px">
            <div class="addIconSon smallHand" @click="close">
              <i
                class="el-icon-close"
                style="font-size: 16px; font-weight: 600"
              ></i>
            </div>
          </div>

          <!-- <div class="addSuccessIcon" style="margin-bottom: 10px">
            <div class="addSuccessIconSon">
              <i
                class="el-icon-check"
                style="font-size: 26px; font-weight: 600; color: white"
              ></i>
            </div>
          </div> -->

          <div class="addTitle" style="margin-bottom: 10px">加入购物车成功</div>

          <div class="addText" style="margin-bottom: 30px">
            <span style="margin-right: 10px"
              >目前选购商品共
              <span style="color: #fa0100; font-weight: 600">{{
                speciesNumber
              }}</span>
              种
              <span style="color: #fa0100; font-weight: 600">{{
                pieceNumber
              }}</span>
              件</span
            >
            <span
              >合计：<span style="color: #fa0100; font-weight: 600">{{
                price
              }}</span></span
            >
          </div>

          <div class="addButtonBox">
            <div
              class="buttonBox smallHand"
              style="
                background-color: #eeeeee;
                border-radius: 6px;
                color: #000000;
                margin-right: 20px;
              "
              @click="close"
            >
              继续购物
            </div>
            <div
              class="buttonBox smallHand"
              style="
                background-color: #fa0100;
                border-radius: 6px;
                color: white;
              "
              @click="goCart"
            >
              进入购物车
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      speciesNumber: 0,
      pieceNumber: 0,
      price: 0,
    }
  },
  methods: {
    open(val1, val2, val3) {
      this.speciesNumber = val1
      this.pieceNumber = val2
      this.price = val3
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
    },
    goCart() {
      this.close()
      window.open('/#/system/shoppingCart/cartIndex')
    },
  },
}
</script>

<style lang="less" scoped>
.dialogCss {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9;
}
.addCartBox {
  width: 100%;
  height: 100%;
  position: relative;
}
.addCartBoxSon {
  position: absolute;
  width: 350px;
  background: #ffffff;
  border-radius: 5px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 10px 0px 15px 0px;
  .addIcon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-right: 10px;
    .addIconSon {
      width: 30px;
      height: 30px;
      background-color: #f6f6f6;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.addSuccessIcon {
  width: 100%;
  display: flex;
  justify-content: center;
  .addSuccessIconSon {
    width: 45px;
    height: 45px;
    background-color: #29c844;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.addTitle {
  width: 100%;
  text-align: center;
  font-family: Source Han Sans SC, Source Han Sans SC;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.addText {
  font-family: Source Han Sans SC, Source Han Sans SC;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-align: center;
}

.addButtonBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  .buttonBox {
    box-sizing: border-box;
    padding: 7px 14px;
    font-family: Source Han Sans SC, Source Han Sans SC;
    // font-weight: 600;
    font-size: 14px;
  }
}
</style>
